<template>
  <div class="Home px-5">
    <v-row class="py-5" justify="center">
      <v-col cols="5" lg="3">
        <div style="width: 90%;">
          <v-autocomplete
            :items="list.process_jobs"
            return-object
            item-text="car_plate"
            item-value="_id"
            outlined
            dense
            label="ค้นหาเลขทะเบียน เพื่อบันทึกลูกค้ารับงาน"
            autocomplete="off"
            auto-select-first
          >
          </v-autocomplete>
        </div>
      </v-col>

      <v-col cols="5" lg="3">
        <div style="width: 90%;">          
          <v-autocomplete
            :items="list.documents"
            return-object
            item-text="car_plate"
            item-value="_id"
            outlined
            dense
            label="ค้นหาเลขทะเบียน ชื่อลูกค้า"
            autocomplete="off"
            auto-select-first
          >          
            <template slot="item" slot-scope="{ item }"> {{item.car_plate}} > 
              {{item.document_type == 0 ? "ใบเสนอราคา" : ""}}
              {{item.document_type == 1 ? "ใบแจ้งหนี้" : ""}}
              {{item.document_type == 2 ? "ใบเสร็จรับเงิน" : ""}}
              {{item.document_type == 3 ? "ใบเพิ่มหนี้" : ""}}
              {{item.document_type == 4 ? "ใบลดหนี้" : ""}}
            </template>
          </v-autocomplete>
        </div>
      </v-col>

      <v-col cols="5" lg="3">
        <div style="width: 90%;">
          <v-select
            v-model="form.branch"
            :items="list.branchs"
            item-value="_id"
            item-text="branch_name"
            return-object
            prepend-inner-icon="mdi-magnify"
            label="เลือกสาขา"
            @change="onFilterChange"
            outlined
            rounded
            dense
            hide-details
          ></v-select>
        </div>
      </v-col>
      
      <v-col cols="5" lg="3" class="d-flex">
        <div style="width: 40%;">
          <!-- <v-text-field label="เดือน" outlined rounded dense hide-details></v-text-field> -->
          <v-select 
            label="ช่วงเวลา" 
            v-model="timeRangeType" 
            :items="timeRangeTypes"
            item-value="value"
            item-text="text"
            outlined 
            rounded 
            dense 
            hide-details>
          </v-select>
        </div>
        <div style="width: 10%;"></div>
        <div style="width: 40%;">
          <!-- <v-text-field label="ปี" outlined rounded dense hide-details></v-text-field> -->
          <!-- <DatePicker 
            v-if="timeRangeType == 1" 
            label="เดือน" 
            :value="form.start_date"
            @onChange="(val) => (form.start_date = val)" 
            hideDetails 
            outlined 
            rounded 
            dense
          /> -->
          <v-menu v-if="timeRangeType == 1"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field 
                :value="form.start_date ? getMonth(form.start_date) :''"
                label="เดือน" 
                prepend-icon="mdi-calendar" 
                v-bind="attrs" 
                v-on="on" 
                hideDetails 
                outlined 
                rounded 
                dense 
                readonly>
              </v-text-field>
            </template>
            <v-date-picker 
              v-model="month"
              type="month" 
              no-title s
              crollable 
              locale="th">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="onSaveMonth(month)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">

      <v-col cols="5" lg="3">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" class="black--text-x text-h6">งานที่รอไปต่อภาษี</v-col>
              <v-col cols="6" class="black--text text-h2">{{tax_extension_job_count}}</v-col>
              <v-col cols="6" class="text-right">
                <v-btn icon elevation="1" color="red" large>
                  <v-icon color="primary">mdi-file</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="5" lg="3">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" class="black--text-x text-h6">งานขนส่ง และงานอื่นๆ - กำลังดำเนินการ</v-col>
              <v-col cols="6" class="black--text text-h2">{{on_process_job_count}}</v-col>
              <v-col cols="6" class="text-right">
                <v-btn icon elevation="1" color="red" large>
                  <v-icon color="primary">mdi-chart-bar</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="5" lg="3">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" class="black--text-x text-h6">แจ้งเตือนลูกค้าต่ออายุ</v-col>
              <v-col cols="6" class="black--text text-h2">{{renewal_alert_count}}</v-col>
              <v-col cols="6" class="text-right">
                <v-btn icon elevation="1" color="red" large>
                  <v-icon color="primary">mdi-bell</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="5" lg="3">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" class="black--text-x text-h6">จำนวนใบเสร็จ</v-col>
              <v-col cols="6" class="black--text text-h2">{{receipt_count}}</v-col>
              <v-col cols="6" class="text-right">
                <v-btn icon elevation="1" color="red" large>
                  <v-icon color="primary">mdi-cash-usd</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <v-row class="mt-5" justify="center">

      <v-col cols="10" lg="6">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="text-h6 black--text-x">แจ้งเตือนภายใน</v-col>
              <v-col cols="6" class="text-right">
                <v-btn color="primary" outlined small><v-icon left>mdi-plus</v-icon> เพิ่มแจ้งเตือน</v-btn>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="alert.headers"
                  :items="alert.list"
                  :search="alert.search"
                  :loading="alert.loading"
                  sort-by="alert.alert_time"
                  :loading-text="$table_loading_text"
                  :page.sync="alert.page"
                  hide-default-footer
                >
                  <template v-slot:[`item.alert_time`]="{ item }">
                    {{item.alert_date}} {{item.alert_time}}
                  </template>
                  <template v-slot:footer>
                    <table-pagination-no-item-per-page 
                      :page.sync="alert.page" 
                      :length="alert.pageCount" 
                      @change-page="onInternalAlertChangePage"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="10" lg="6">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-h6 black--text-x">ยอดค้างชำระทั้งหมด</v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="bill.headers"
                  :items="bill.list"
                  :search="bill.search"
                  :loading="bill.loading"
                  sort-by="bill.due_date"
                  :loading-text="$table_loading_text"
                  :page.sync="bill.page"
                  hide-default-footer
                >
                  <template v-slot:[`item.due_date`]="{ item }">
                    {{item.bill_date}} {{item.bill_time}}
                  </template>
                  <template v-slot:footer>
                    <table-pagination-no-item-per-page 
                      :page.sync="bill.page" 
                      :length="bill.pageCount"
                      @change-page="onUnpaidDocumentChangePage"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <v-row justify="center">
      <v-col cols="10" lg="7">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="text-h6 black--text-x">สรุปยอดเงินเก็บ</v-col>
              <v-col cols="4">
                <div style="width:200px;">
                  <v-checkbox 
                    label="รวมค่าภาษีด้วย" 
                    v-model="form.is_profit_inlude_tax"
                    @change="handleTaxIncludeChartSumary"
                    hide-details
                    dense
                    class="mt-0"
                  >
                  </v-checkbox>
                </div>
                <div class="fz-2 py-2">เก็บเงินแล้ว</div>
                <div class="fz-5 black--text text-h5">฿ {{form.is_profit_inlude_tax ? profit : profit_exclude_tax}}</div>
              </v-col>
              <v-col cols="12">
                <canvas id="chart-sumary"></canvas>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" lg="5">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-col class="text-h6 black--text-x">ยอดขายตามสินค้า</v-col>
              <v-col cols="4">
                <div style="width:200px;">
                  <v-checkbox 
                    label="แสดงค่าภาษี" 
                    v-model="form.is_product_inlude_tax"
                    @change="handleTaxIncludeChartProduct"
                    hide-details 
                    dense 
                    class="mt-0"
                  >
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12">
                <canvas id="chart-product"></canvas>
              </v-col>

              <!-- v-for="(item, i) in this.form.is_product_inlude_tax ? this.product_profit.label.length : this.product_profit_tax_exclude.label.length" -->
              <v-col cols="12"
                v-for="(item, i) in this.form.is_product_inlude_tax ? this.product_profit.label.length : this.product_profit_tax_exclude.label.length"
                :key="i"
              >
                <v-row v-if="i<5">
                  <v-col cols="6" lg="5" class="fz-5">
                    <v-icon :color="form.is_product_inlude_tax ? product_profit.color[i] : product_profit_tax_exclude.color[i]" left>mdi-checkbox-blank</v-icon> 
                    {{form.is_product_inlude_tax ? product_profit.label[i] : product_profit_tax_exclude.label[i]}}
                  </v-col>
                  <v-col cols="6" lg="7" class="fz-5 black--text text-right font-weight-bold">
                    {{form.is_product_inlude_tax ? Math.floor((product_profit.sum[i] + Number.EPSILON) * 100) / 100 : Math.floor((product_profit_tax_exclude.sum[i] + Number.EPSILON) * 100) / 100}}
                    ({{form.is_product_inlude_tax ? product_profit.percent[i] : product_profit_tax_exclude.percent[i]}}%)
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-divider/>
                <div class="primary--text fz-4 mt-8">ดูเพิ่มเติม</div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>


<script>
import DatePicker from "@/components/DatePicker.vue";
import Chart from 'chart.js';

import * as moment from "moment";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    form: {
      branch: '',
      internal_alert_page: 1,
      document_page: 1,
      start_date: moment().add(-30, "days").set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss"),
      end_date: moment().set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss"),
      is_profit_inlude_tax: false,
      is_product_inlude_tax: false,
    },
    list: {
      process_jobs: [],
      documents: [],
      branchs: [],
    },
    timeRangeType: 0,
    timeRangeTypes: [
      { value: 0, text: "เดือนปัจจุบัน"},
      { value: 1, text: "เดือนก่อน"},
    ],
    menu: false,
    month: null,
    tax_extension_job_count: 0,
    on_process_job_count: 0,
    renewal_alert_count: 0,
    receipt_count: 0,
    profit: 0,
    profit_exclude_tax: 0,
    profit_summary: { 
      label: [],
      tax_include: [],
      tax_exclude: []
    },
    product_profit: {
      label: [],
      percent: [],
      sum: [],
      color: []
    },
    product_profit_tax_exclude: {
      label: [],
      percent: [],
      sum: [],
      color: []
    },
    alert: {
      search: '',
      selected: [],
      loading: true,
      page: 1,
      pageCount: 0,
      // itemsPerPage: 10,
      list: [],
      headers: [
        { text: 'วันที่', value: 'alert_time'},
        { text: 'ชื่อแจ้งเตือน', value: 'topic', filterable: false },
        { text: 'รายละเอียด', value: 'detail', filterable: false },
      ],
    },
    bill: {
      search: '',
      selected: [],
      loading: true,
      page: 1,
      pageCount: 0,
      // itemsPerPage: 10,
      list: [],
      headers: [
        { text: 'วันที่', value: 'due_date'},
        { text: 'เลขที่', value: 'number', filterable: false },
        { text: 'ชื่อ', value: 'customer_name', filterable: false },
        { text: 'ชื่อโปรเจ็ก', value: 'project_name', filterable: false },
        { text: 'ราคา', value: 'net_price', filterable: false },
      ],
    }
  }),

  mounted () {
    // this.createChartSumary();
    // this.createChartProduct();
  },
  async created () {
    await this.getBranchs()
    await this.getProcess()
    await this.getDocument()
    await this.getInternalAlert()
    await this.getUnpaidDocument()
    await this.getDashboardData()

  },
  methods: {
    async getBranchs() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios.post(`${this.$baseUrl}/branch/get_branch_list`, body)
        .then(async (res) => {
          // this.list.branchs = [{ "_id": "", "branch_name": "ทั้งหมด"}];
          // for(let val of res.result) {
          //   this.list.branchs.push(val);
          // }
          this.list.branchs =  [].concat([{ "_id": "", "branch_name": "ทั้งหมด"}], res.result)
          this.form.branch = this.list.branchs[0]
        });
    },
    onFilterChange () {        
      this.alert.loading = true;
      this.bill.loading = true;
      this.getProcess()
      this.getDocument()
      this.getInternalAlert()
      this.getUnpaidDocument()
      this.getDashboardData()
    },
    async getProcess() {
      let filter = {
        branch_id: this.form.branch._id,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/dashboard/get_process`, body)
        .then(async (res) => {
          this.list.process_jobs = res.result;
        });
    },
    async getDocument() {
      let filter = {
        branch_id: this.form.branch._id,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/dashboard/get_document`, body)
        .then(async (res) => {
          this.list.documents = res.result;
        });
    },
    async getInternalAlert() {
      let filter = {
        branch_id: this.form.branch._id,
        internal_alert_page: this.alert.page,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/dashboard/get_internal_alert`, body)
        .then(async (res) => {
          this.alert.loading = false;
          this.alert.list = res.result.data
          this.alert.list.forEach((item, i) => {
            this.alert.list[i].alert_date = moment(item.alert_time, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY");
            this.alert.list[i].alert_time = moment(item.alert_time, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
          });
          this.alert.pageCount = res.result.total_page || 1
        });
    },
    async getUnpaidDocument() {
      let filter = {
        branch_id: this.form.branch._id,
        document_page: this.bill.page,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/dashboard/get_unpaid_document`, body)
        .then(async (res) => {
          this.bill.loading = false;
          this.bill.list = res.result.data
          this.bill.list.forEach((item, i) => {
            this.bill.list[i].bill_date = moment(item.due_date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY");
            this.bill.list[i].bill_time = moment(item.due_date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
          });
          this.bill.pageCount = res.result.total_page || 1
        });
    },
    async getDashboardData() {
      let filter = {
        branch_id: this.form.branch._id,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/dashboard/get_data`, body)
        .then(async (res) => {
          this.tax_extension_job_count = res.result.tax_extension_job_count
          this.on_process_job_count = res.result.on_process_job_count
          this.renewal_alert_count = res.result.renewal_alert_count
          this.receipt_count = res.result.receipt_count
          this.profit = res.result.profit_summary
          this.profit_exclude_tax = res.result.profit_summary_no_tax
          res.result.profit_summary_array.forEach((item, i) => {
            this.profit_summary.label[i] = item._id;
            this.profit_summary.tax_include[i] = item.sum;
            this.profit_summary.tax_exclude[i] = item.sum_no_tax;
          });
          res.result.product_profit_array.forEach((item, i) => {
            this.product_profit.label[i] = item._id;
            this.product_profit.percent[i] = item.percent;
            this.product_profit.sum[i] = item.sum;
            this.product_profit.color[i] = this.randomHexColor();
          });
          res.result.product_profit_no_tax_array.forEach((item, i) => {
            this.product_profit_tax_exclude.label[i] = item._id;
            this.product_profit_tax_exclude.percent[i] = item.percent;
            this.product_profit_tax_exclude.sum[i] = item.sum;
            this.product_profit_tax_exclude.color[i] = this.randomHexColor();
          });
          this.createChartSumary()
          this.createChartProduct()
        });
    },
    formatDate(date) {
      if (!date) return "";
      let year = parseInt(moment(this.form.registration_date).format( "YYYY" ))
      let m_d = moment(this.form.registration_date).format("DD/MM")
      return `${m_d}/${year+543}`
    },
    async handleTaxIncludeChartSumary(checked){
      this.createChartSumary()
    },
    async handleTaxIncludeChartProduct(checked){
      this.createChartProduct()
    },
    randomHexColor() {
      return '#'+Math.floor(Math.random()*16777215).toString(16);
    },
    createChartSumary() {
      var ctx = document.getElementById('chart-sumary').getContext("2d");
      if(window.myChart instanceof Chart) window.myChart.destroy();
      window.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.profit_summary.label,
          datasets: [
            {
              data: this.form.is_profit_inlude_tax ? this.profit_summary.tax_include : this.profit_summary.tax_exclude,
              backgroundColor: '#2B6AE1'
            }
          ]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return  ' '+tooltipItem.yLabel+' บาท';
              }
            }
          },
          /* scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          } */
        }
      });
    },

    createChartProduct() {
      var ctx = document.getElementById('chart-product');
      if(window.myChartProduct instanceof Chart) window.myChartProduct.destroy();
      window.myChartProduct = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: this.form.is_product_inlude_tax ? this.product_profit.percent : this.product_profit_tax_exclude.percent,
            backgroundColor: this.form.is_product_inlude_tax ? this.product_profit.color : this.product_profit_tax_exclude.color,
          }],
          labels: this.form.is_product_inlude_tax ? this.product_profit.label : this.product_profit_tax_exclude.label,
        },
        options: {
          legend: {
            display: false
          },
          // tooltips: {
          //   callbacks: {
          //     label: function(tooltipItem) {
          //       return  ' '+tooltipItem.yLabel+' บาท';
          //     }
          //   }
          // },
          
          // scales: {
          //   yAxes: [{
          //     ticks: {
          //       beginAtZero: true
          //     }
          //   }]
          // }
        }
      });
    },
    onInternalAlertChangePage(page){
      this.alert.page = page
      this.getInternalAlert()
    },
    
    onUnpaidDocumentChangePage(page){
      this.bill.page = page
      this.getUnpaidDocument()
    },
    onSaveMonth(date){
      this.$refs.menu.save(date);
      this.form.start_date = moment(date, "YYYY-MM").set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
      this.form.end_date = moment(date, "YYYY-MM").endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
      this.onFilterChange()
    },
    getMonth(date){
      return moment(date).format("YYYY-MM");
    },
    

  },
  watch: {
      timeRangeType: function(value, oldValue){
        if(value == 0){
          // 0 = เดือนปัจจุบัน
          this.form.start_date = moment().startOf('month').set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
          this.form.end_date = moment().endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
        } else if(value == 1){
          // 1 = เดือนก่อน 
          this.form.start_date = moment().subtract(1, 'months').startOf('month').set({hour:0,minute:0,second:0}).format("YYYY-MM-DDTHH:mm:ss");
          this.form.end_date = moment().subtract(1, 'months').endOf('month').set({hour:23,minute:59,second:59}).format("YYYY-MM-DDTHH:mm:ss");
          this.month = moment().subtract(1, 'months').format("YYYY-MM");
        }
        this.onFilterChange()
      }
    }
}
</script>